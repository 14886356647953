var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{attrs:{"app":"","permanent":"","left":"","width":"200","color":"#F9F9F9","floating":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-app-bar',{staticClass:"mt-3",attrs:{"color":"#F9F9F9","dense":"","outlined":"","light":"","elevation":"0"}},[_c('img',{staticClass:"brand_logo",attrs:{"src":require("@/assets/aprotime-logo.svg")}}),_c('p',{staticClass:"brand_text"},[_vm._v("Aprotime")])]),_c('div',{staticClass:"scrollable-headers my-8 custom-scrollbar"},[(_vm.userDetails?.roles?.has_all_access)?_c('v-list',{attrs:{"subheader":"","flat":"","nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"link","color":"#000"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.filteredLinks),function(header,index){return _c('div',{key:index},[_c('v-subheader',{staticClass:"category_text flex items-center",attrs:{"color":"black"},on:{"click":function($event){return _vm.toggleHeader(header.index)}}},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('v-icon',{style:({
                  color: 'black',
                  borderRadius: '50%',
                })},[_vm._v(" "+_vm._s(header.header === "Time Tracking" ? "mdi-clock" : header.header === "Payments" ? "mdi-invoice-text" : header.header === "Management" ? "mdi-cog" : "mdi-note-text")+" ")]),_c('span',{staticClass:"header-text"},[_vm._v(_vm._s(header.header))])],1),_c('div',[_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v(" "+_vm._s(_vm.isHeaderOpen(header.index) ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]),(_vm.isHeaderOpen(header.index))?_vm._l((header.items),function(item){return _c('v-list-item',{key:item.title,class:[
                'flex pl-12 text-sm',
                { 'font-semibold': _vm.$route.path === item.route },
              ],attrs:{"router":"","to":item.route,"color":_vm.$route.path === item.route ? 'primary' : 'black'}},[_vm._v(" "+_vm._s(item.title)+" ")])}):_vm._e()],2)}),0)],1):_c('v-list',{attrs:{"subheader":"","flat":"","nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"link","color":"#000"},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}},_vm._l((_vm.filteredLinks),function(header,index){return _c('div',{key:index},[_c('v-subheader',{staticClass:"category_text flex items-center",attrs:{"color":"black"},on:{"click":function($event){return _vm.toggleHeader(header.index)}}},[_c('div',{staticClass:"flex items-center space-x-2"},[_c('v-icon',{style:({
                  color: 'black',
                  borderRadius: '50%',
                })},[_vm._v(" "+_vm._s(header.header === "Time Tracking" ? "mdi-clock" : header.header === "Payments" ? "mdi-invoice-text" : header.header === "Management" ? "mdi-cog" : "mdi-note-text")+" ")]),_c('span',{staticClass:"header-text"},[_vm._v(_vm._s(header.header))])],1),_c('div',[_c('v-icon',{attrs:{"size":"20","color":"black"}},[_vm._v(" "+_vm._s(_vm.isHeaderOpen(header.index) ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)]),(_vm.isHeaderOpen(header.index))?_vm._l((header.items),function(item){return _c('v-list-item',{key:item.title,class:[
                'flex pl-12 text-sm',
                { 'font-semibold': _vm.$route.path === item.route },
              ],attrs:{"router":"","to":item.route,"color":_vm.$route.path === item.route ? 'primary' : 'black'}},[_vm._v(" "+_vm._s(item.title)+" ")])}):_vm._e()],2)}),0)],1)],1),_c('v-footer',{staticClass:"px-10",attrs:{"color":"#F9F9F9","padless":""}},[_c('v-row',{},[_c('span',{staticClass:"me-2 nav_text"},[_vm._v(" "+_vm._s(_vm.userDetails.first_name)+" "+_vm._s(_vm.userDetails.last_name)+" ")]),_c('div',[(_vm.userDetails?.logo)?_c('img',{staticClass:"rounded",staticStyle:{"width":"30px","height":"30px","object-fit":"cover"},attrs:{"src":_vm.userDetails?.logo,"alt":"User Avatar"}}):_c('v-avatar',{attrs:{"rounded":"","size":"30","color":"#D9D9D9"}},[_c('v-icon',{attrs:{"size":"20","color":"#8C8C8C","dark":""}},[_vm._v("mdi-account")])],1)],1),_c('p',{style:({
          color: 'black',
          fontSize: '12px',
        })},[_vm._v(" Version 0.01 ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="basePage">
        <NavBar />
        <SideBar />
        <router-view />
    </div>
</template>

<script>

import NavBar from '@/components/layouts/NavBar.vue'
import SideBar from '@/components/layouts/SideBar.vue'
export default {
    name: 'basePage',
    components: {
        NavBar, SideBar,
    },
    computed: {
        
    }
}
</script>

<style scoped>
.basePage {
    width: 100%;
}
</style>
